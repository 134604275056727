<template>

  <h2 class="text-black py-4 text-center">Nr.1</h2>

  <div class="imagineTxt flex justify-center">
    <svg version="1.1" id="imagination_txt" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 580 455" width="580" height="455" style="enable-background:new 0 0 580 555; overflow:visible;" xml:space="preserve">

      <g id="txt_takes_design">
        <path class="whiteTxt2" d="M7.6,127.4v26.4H6v-26.4H1.4V126h10.8v1.4H7.6z" />
        <path class="whiteTxt2" d="M22.8,153.8l-1.7-8.3h-6.5l-1.6,8.3h-1.7l6-27.8h1.4l5.9,27.8H22.8z M17.9,129.2L15,144h5.9L17.9,129.2z" />
        <path class="whiteTxt2" d="M36.4,153.8l-5.7-14.5l-2.9,5.7v8.9h-1.6V126h1.6v15.8l7.9-15.8h1.7l-5.7,11.4l6.4,16.3H36.4z" />
        <path class="whiteTxt2" d="M40,153.8V126h9v1.4h-7.4v11.8H48v1.4h-6.4v11.8H49v1.4H40z" />
        <path class="whiteTxt2" d="M60.9,152.6c-0.9,0.9-2.1,1.4-3.9,1.4c-1.7,0-3-0.4-4-1.4c-1.2-1.2-1.6-2.5-1.6-5.6h1.6c0,2.4,0.3,3.6,1.2,4.5c0.6,0.6,1.6,1,2.8,1c1.4,0,2.3-0.4,2.8-1c1.1-1.1,1.2-2.4,1.2-4.8c0-3.7-0.6-5.1-3-6.1l-2.8-1.2c-2.8-1.2-3.6-2.8-3.6-6.9c0-2.3,0.6-4.2,1.8-5.3c0.9-0.9,2.2-1.4,3.8-1.4s3,0.5,3.9,1.4c1.3,1.4,1.6,2.8,1.6,5.1H61c0-1.9-0.3-3.1-1.2-4.1c-0.6-0.7-1.5-1.1-2.8-1.1c-1.2,0-2,0.4-2.6,0.9c-1,0.9-1.4,2.5-1.4,4.4c0,3.5,0.5,4.6,3.2,5.7l2.8,1.2c2.9,1.2,3.4,3.5,3.4,7.3C62.6,149.6,62.1,151.4,60.9,152.6z"
        />
        <path class="whiteTxt2" d="M81.4,152.4c-0.9,0.9-2.1,1.3-3.7,1.3h-5.2V126h5.2c1.5,0,2.8,0.5,3.7,1.3c1.6,1.7,1.9,3.1,1.9,12.6S83.1,150.8,81.4,152.4z M80.2,128.3c-0.6-0.6-1.5-0.9-2.6-0.9h-3.5v25h3.5c1.1,0,2-0.3,2.6-0.9c1.3-1.3,1.5-2.5,1.5-11.6C81.8,130.8,81.6,129.6,80.2,128.3z"
        />
        <path class="whiteTxt2" d="M87.1,153.8V126h9v1.4h-7.4v11.8h6.4v1.4h-6.4v11.8h7.4v1.4H87.1z" />
        <path class="whiteTxt2" d="M107.9,152.6c-0.9,0.9-2.1,1.4-3.9,1.4c-1.7,0-3-0.4-4-1.4c-1.2-1.2-1.6-2.5-1.6-5.6h1.6c0,2.4,0.3,3.6,1.2,4.5c0.6,0.6,1.6,1,2.8,1c1.4,0,2.3-0.4,2.8-1c1.1-1.1,1.2-2.4,1.2-4.8c0-3.7-0.6-5.1-3-6.1l-2.8-1.2c-2.8-1.2-3.6-2.8-3.6-6.9c0-2.3,0.6-4.2,1.8-5.3c0.9-0.9,2.2-1.4,3.8-1.4s3,0.5,3.9,1.4c1.3,1.4,1.6,2.8,1.6,5.1h-1.6c0-1.9-0.3-3.1-1.2-4.1c-0.6-0.7-1.5-1.1-2.8-1.1c-1.2,0-2,0.4-2.6,0.9c-1,0.9-1.4,2.5-1.4,4.4c0,3.5,0.5,4.6,3.2,5.7l2.8,1.2c2.9,1.2,3.4,3.5,3.4,7.3C109.7,149.6,109.1,151.4,107.9,152.6z"
        />
        <path class="whiteTxt2" d="M113.1,153.8V126h1.6v27.8H113.1z" />
        <path class="whiteTxt2" d="M124.1,154.1c-1.5,0-2.8-0.5-3.7-1.4c-1.6-1.7-2-3.2-2-12.8s0.3-11.2,2-12.8c0.9-0.9,2.2-1.4,3.7-1.4c1.6,0,2.8,0.5,3.7,1.4c1.1,1.2,1.6,2.9,1.6,4.9h-1.6c0-2.5-0.7-4.9-3.7-4.9c-1.1,0-2,0.4-2.6,1c-1.2,1.4-1.5,2.6-1.5,11.8s0.2,10.5,1.5,11.8c0.6,0.6,1.5,1,2.6,1c2,0,3.7-1.5,3.7-4.4v-7.7h-3.7v-1.4h5.3v9.2C129.3,152.2,126.8,154.1,124.1,154.1z"
        />
        <path class="whiteTxt2" d="M142.9,153.8l-8.2-23.5v23.5h-1.6V126h1.7l8.2,23.5V126h1.6v27.8H142.9z" />
        <path class="yellowTxt" d="M155.4,153.8V126h9v1.4H157v11.8h6.4v1.4H157v11.8h7.4v1.4H155.4z" />
        <path class="yellowTxt" d="M172.6,153.8h-1.5l-5.9-27.8h1.7l4.9,24.6l4.9-24.6h1.7L172.6,153.8z" />
        <path class="yellowTxt" d="M180.1,153.8V126h9v1.4h-7.4v11.8h6.4v1.4h-6.4v11.8h7.4v1.4H180.1z" />
        <path class="yellowTxt" d="M202.2,153.8l-4.2-12.8h-4v12.8h-1.6V126h4.9c2.7,0,5.6,0.7,5.6,7.5c0,4.6-1.3,6.6-3.5,7.2l4.4,13.1H202.2z M197.6,127.4h-3.5v12.2h3.5c2.9,0,3.9-2,3.9-6.1C201.5,129.4,200.5,127.4,197.6,127.4z" />
        <path class="yellowTxt" d="M210.6,141.9v11.9h-1.6v-11.9l-4.7-15.9h1.7l3.7,13.3l3.8-13.3h1.7L210.6,141.9z" />
        <path class="yellowTxt" d="M231.3,153.8h-1.6l-4.9-24.6l-4.8,24.6h-1.6l-3.9-27.8h1.7l3,24.6l4.8-24.6h1.8l4.8,24.6l3-24.6h1.7L231.3,153.8z" />
        <path class="yellowTxt" d="M245.8,153.8v-13.4h-7.4v13.4h-1.6V126h1.6v12.9h7.4V126h1.6v27.8H245.8z" />
        <path class="yellowTxt" d="M251.7,153.8V126h9v1.4h-7.4v11.8h6.4v1.4h-6.4v11.8h7.4v1.4H251.7z" />
        <path class="yellowTxt" d="M273.9,153.8l-4.2-12.8h-4v12.8h-1.6V126h4.9c2.7,0,5.6,0.7,5.6,7.5c0,4.6-1.3,6.6-3.5,7.2l4.4,13.1H273.9z M269.2,127.4h-3.5v12.2h3.5c2.9,0,3.9-2,3.9-6.1C273.1,129.4,272.1,127.4,269.2,127.4z" />
        <path class="yellowTxt" d="M279.1,153.8V126h9v1.4h-7.4v11.8h6.4v1.4h-6.4v11.8h7.4v1.4H279.1z" />
        <path class="whiteTxt2" d="M298,153.8V126h9v1.4h-7.4v11.8h6.4v1.4h-6.4v11.8h7.4v1.4H298z" />
        <path class="whiteTxt2" d="M310.5,153.8V126h1.6v26.4h7.4v1.4H310.5z" />
        <path class="whiteTxt2" d="M330.2,152.6c-0.9,0.9-2.1,1.4-3.9,1.4c-1.7,0-3-0.4-4-1.4c-1.2-1.2-1.6-2.5-1.6-5.6h1.6c0,2.4,0.3,3.6,1.2,4.5c0.6,0.6,1.6,1,2.8,1c1.4,0,2.3-0.4,2.8-1c1.1-1.1,1.2-2.4,1.2-4.8c0-3.7-0.6-5.1-3-6.1l-2.8-1.2c-2.8-1.2-3.6-2.8-3.6-6.9c0-2.3,0.6-4.2,1.8-5.3c0.9-0.9,2.2-1.4,3.8-1.4s3,0.5,3.9,1.4c1.3,1.4,1.6,2.8,1.6,5.1h-1.6c0-1.9-0.3-3.1-1.2-4.1c-0.6-0.7-1.5-1.1-2.8-1.1c-1.2,0-2,0.4-2.6,0.9c-1,0.9-1.4,2.5-1.4,4.4c0,3.5,0.5,4.6,3.2,5.7l2.8,1.2c2.9,1.2,3.4,3.5,3.4,7.3C331.9,149.6,331.4,151.4,330.2,152.6z"
        />
        <path class="whiteTxt2" d="M335.3,153.8V126h9v1.4h-7.4v11.8h6.4v1.4h-6.4v11.8h7.4v1.4H335.3z" />
      </g> </svg>
  </div>


  <h2 class="text-black py-4 text-center">Nr.2</h2>

<div class="balls flex">
  <div class="ball inline-table mx-auto mt-20"></div>
</div>
<div class="balls2">
  <div class="ball2"></div>
  <div class="ball3"></div>
  <div class="ball4"></div>
</div>


</template>

<script>

import {gsap} from "gsap";
import { EaselPlugin } from 'gsap/EaselPlugin'
import { TextPlugin } from 'gsap/TextPlugin'
gsap.registerPlugin(EaselPlugin);
gsap.registerPlugin(TextPlugin);
export default {
  name: "EaselPlugin",


  mounted() {

    const tlslideTxt = gsap.timeline({repeat: -1});
    tlslideTxt
        .from(".whiteTxt2, .yellowTxt", .6, {
          x: -300, opacity: 0,
          ease: "expo.inOut"
        })
        .to(".whiteTxt2, .yellowTxt", 2.5, {
          x: 300,
          ease: "circ.in"
        });


    gsap
        .timeline({
          repeat: -1,
          defaults: {
            duration: 1.5
          }
        })
        .to('.ball', {
          x: 200,
          scale: 2,
          ease: "bounce"
        })
        .to('.ball', {
          x: 0,
          scale: 1,
          ease: "back.inOut(3)"
        })
        .to('.ball', {
          x: -200,
          scale: 2,
          ease: "bounce"
        })
        .to('.ball', {
          x: 0,
          scale: 1,
          ease: "back.inOut(3)"
        });

    gsap
        .timeline({
          defaults: {
            duration: 1.5
          }
        })
        .to('.ball2', {
          x: 300,
          scale: 2,
          ease: "bounce"
        })
        .to('.ball3', {
          x: 300,
          scale: 2,
          ease: "bounce"
        })
        .to('.ball4', {
          x: 300,
          scale: 2,
          ease: "bounce"
        })





    

  }

}
</script>

<style scoped lang="scss">


.whiteTxt2{
  fill:#ffffff;
}

.yellowTxt{

  fill:#F4D20C;}

.imagineTxt{
  position: relative;

  height:22vh;
  width:100%;
background-color: #b55a90;
}
#imagination_txt {
  overflow:visible;

}

.balls {

  background: #2F243A;
}

.ball {

  position: relative;
  background: #DB8A74;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}


.ball2 {
  background: #DB8A74;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}


.ball3 {
  background: #d16b4f;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.ball4 {
  background: #b63b19;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.balls2 {

  background: #2F243A;
  display: flex;
  width: 99vw;
  height: 35vh;
  justify-content: center;
  align-items: center;
}




</style>